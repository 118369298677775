import React from "react"

import styles from "../styles/components/section-header.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const SectionHeader = ({ title, subtitle, subtitleLarge, subtitle2 }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      {subtitle && (
        <p className={cx("subtitle", { large: subtitleLarge })}>{subtitle}</p>
      )}
      {subtitle2 && <p className={styles.subtitle}>{subtitle2}</p>}
    </div>
  )
}

export default SectionHeader
