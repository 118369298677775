import React from "react"
import { Link } from "gatsby"

import styles from "../styles/components/info-box.module.scss"

const InfoBox = props => {
  return (
    <div className={styles.infoBox}>
      <div className={styles.iconContainer}>{props.icon}</div>
      <h3>{props.title}</h3>
      <p>{props.subtext}</p>
      <div className={styles.linkContainer}>
        {props.linkTo && <Link to={props.linkTo}>{props.linkText}</Link>}
        {props.linkHref && (
          <a href={props.linkHref} target="_blank" rel="noreferrer">
            {props.linkText}
          </a>
        )}
      </div>
    </div>
  )
}

export default InfoBox
