import React from "react"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import styles from "../styles/components/image-titled.module.scss"

const ImageTitled = props => {
  return (
    <Fade bottom distance="20%">
      <div className={styles.container}>
        {props.title && (
          <div className={styles.text}>
            <h3>{props.title}</h3>
            <p>{props.subtitle}</p>
          </div>
        )}
        <Img className={styles.image} fluid={props.fluid} alt={props.alt} />
      </div>
    </Fade>
  )
}

export default ImageTitled
