import React from "react"

import styles from "../styles/components/section.module.scss"

const Section = ({ children, id }) => {
  return (
    <section className={styles.container} id={id}>
      {children}
    </section>
  )
}

export default Section
