import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import SectionHeader from "../components/sectionHeader"
import InfoBox from "../components/infoBox"
import ImageTitled from "../components/imageTitled"
import Fade from "react-reveal/Fade"
import Banner from "../components/banner"

import styles from "../styles/pages/index.module.scss"

import IconGift from "../images/icon-gift.svg"
import IconTree from "../images/icon-tree.svg"

// 'season-start', 'fully-booked', 'schedule-takedown', 'off-season'
const state = "season-start"

const IndexPage = ({ data }) => {
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" })

  const showConsultations = state === "season-start"
  const showInstall = state === "season-start" || state === "fully-booked"
  const showTakedown = state === "schedule-takedown"

  return (
    <Layout
      showConsultations={showConsultations}
      showInstall={showInstall}
      showTakedown={showTakedown}
    >
      <SEO
        title="Home"
        keywords={[
          "Christmas lights",
          "Pelham",
          "Christmas decorations",
          "Outdoor Christmas lights",
          "Christmas light installation",
        ]}
      />
      {state === "fully-booked" && (
        <Banner>
          <p>
            We're fully booked for the ${new Date().getFullYear() - 1} season!{" "}
            {!isTablet && <br />}
            We are no longer accepting consultations at this time.
          </p>
        </Banner>
      )}
      <section className={styles.heroSection}>
        <div className={styles.heroImageContainer}>
          <Img
            className={styles.heroImage}
            fluid={data.imgHero.childImageSharp.fluid}
            alt="Pelham Lights"
            loading="eager"
          />
          <div className={styles.overlay} />
        </div>
        <Fade bottom distance="20%">
          <h1>Pelham Lights</h1>
          <p
            style={{
              color: "white",
              fontSize: "18px",
              letterSpacing: "0.5px",
              textAlign: "center",
            }}
          >
            Decorative lighting service for the Pelham community.
          </p>
        </Fade>
      </section>
      <div className={styles.background}>
        {state !== "off-season" && (
          <Section id="contact">
            <SectionHeader title="Our service" />
            <div className={styles.infoFlexContainer}>
              {showConsultations && (
                <Fade bottom distance="20%">
                  <InfoBox
                    icon={<IconGift />}
                    title="Schedule Free Consultation"
                    subtext="We offer free in-person consultations to help you decide on the best lighting options for your home."
                    linkText="Schedule Consultation"
                    linkHref="https://meetings.hubspot.com/pelhamlights/consultation"
                  />
                </Fade>
              )}
              {showInstall && (
                <Fade bottom distance="20%">
                  <InfoBox
                    icon={<IconTree />}
                    title="Schedule Installation"
                    subtext="Already a Pelham Lights customer? Schedule your installation now!"
                    linkText="Schedule Installation"
                    linkHref="https://meetings.hubspot.com/pelhamlights/installation"
                  />
                </Fade>
              )}
              {showTakedown && (
                <Fade bottom distance="20%">
                  <InfoBox
                    icon={<IconTree />}
                    title="Schedule Takedown"
                    subtext="Schedule your lights takedown"
                    linkText="Schedule Takedown"
                    linkHref="https://meetings.hubspot.com/pelhamlights/takedown"
                  />
                </Fade>
              )}
            </div>
          </Section>
        )}
        <Section id="options">
          <SectionHeader title="Our winter options" />
          <ImageTitled
            title="Lighted tree branches"
            subtitle="Each tree branch is wrapped in lights for an elegant option."
            fluid={data.imgBranches.childImageSharp.fluid}
            alt="Lighted branches style"
          />
          <ImageTitled
            title="Christmas trees"
            subtitle="Classic, traditional Christmas tree decoration."
            fluid={data.imgChristmasTree.childImageSharp.fluid}
            alt="Christmas trees"
          />
          <ImageTitled
            title="Bushes and hedges"
            subtitle="Festive decoration to light up you your whole yard."
            fluid={data.imgBushes.childImageSharp.fluid}
            alt="Bushes and hedges"
          />
          <Fade bottom distance="50%">
            <p>And more!</p>
          </Fade>
        </Section>
        {/* <Section id="contact">
          <SectionHeader
            title="Sign up now"
            subtitle="to get on our mailing list!"
            subtitleLarge
          />
          <Fade bottom distance="20%">
            <p style={{ marginBottom: "36px" }}>
              You'll be the first to know when we start scheduling the 2021
              holiday season.
            </p>
          </Fade>
          <SignupForm />
        </Section> */}
        <Section>
          <SectionHeader
            title="Spring and summer options"
            subtitle={
              <>
                {state === "off-season" && (
                  <>
                    Just send us an email at{" "}
                    <a href="mailto:info@pelhamlights.com">
                      info@pelhamlights.com
                    </a>{" "}
                    about your lighting needs.
                  </>
                )}
              </>
            }
          />
          <ImageTitled
            fluid={data.imgDeck2.childImageSharp.fluid}
            alt="Outdoor decorative lights"
          />
          <ImageTitled
            title="Indoor and outdoor spaces"
            subtitle="Light up your backyard patio or deck with Pelham Lights."
            fluid={data.imgDeck1.childImageSharp.fluid}
            alt="Outdoor tent with lights"
          />
        </Section>
        <Section id="about">
          <SectionHeader title="About us" />
          <Fade bottom distance="20%">
            <div className={styles.image} style={{ marginBottom: "20px" }}>
              <Img
                fluid={data.imgAbout.childImageSharp.fluid}
                alt="Chris and TJ of Pelham Lights"
              />
            </div>
          </Fade>
          <Fade bottom distance="20%">
            <div style={{ maxWidth: "500px" }}>
              <p>
                Chris has been putting up Christmas lights for his family and
                friends for over six years. This year, Chris and his friend TJ
                are teaming up to offer that expertise to the Pelham community.
              </p>
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                <a href="mailto:info@pelhamlights.com">Get in touch</a> for all
                your festive decoration needs!
              </p>
            </div>
          </Fade>
        </Section>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    imgHero: file(relativePath: { eq: "img-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgBranches: file(relativePath: { eq: "img-branches.JPG" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgChristmasTree: file(relativePath: { eq: "img-christmas-tree.JPG" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgAbout: file(relativePath: { eq: "img-about-us.JPG" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgBushes: file(relativePath: { eq: "img-bushes.JPG" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgDeck1: file(relativePath: { eq: "img-deck1.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgDeck2: file(relativePath: { eq: "img-deck2.JPG" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgDeck3: file(relativePath: { eq: "img-deck3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
